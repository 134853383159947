import SubHeader from "../../components/elements/SubHeader";
import Sidebar from "../../components/plan-template/Sidebar";
import { CollapseContainerContextProvider } from "../../context/collapse-container.context";
import SubNav from "../../components/plan-template/SubNav";
import GoalsContent from "../../components/plan-template/GoalsContent";
import EditorContent from "../../components/plan-template/EditorContent";
import { useState, useEffect } from "react";
import MoveActionModal from "../../components/popups/plan-template/MoveActionPopup";
import { useLoaderData } from "react-router-dom";
import CrudChapterModal from "../../components/popups/plan-template/CrudChapterPopup";
import LayoutSidebar from "../../components/layout/LayoutSidebar";
import CrudContentModal from "../../components/popups/plan-template/CrudContentPopup";
import CrudGoalModal from "../../components/popups/plan-template/CrudGoalPopup";
import ConfirmModal from "../../components/popups/ConfirmPopup";
import usePlanTemplate from "../../hooks/plan-template.hook";
import ActionBtnItem from "../../components/utils/ActionBtnItem";
import StatusBtnItem from "../../components/utils/StatusBtnItem";
import LoaderPage from "../../components/utils/LoaderPage";
import Prompt from "../../components/utils/Prompt";
import EmptyTemplate from "../../components/emptystate/EmptyTemplate";
import { usePlanTemplateStore } from "../../store/plan-template.store";
import StatusPlanCrud from "../../components/popups/StatusPlanCrud";
import { SocketEcho } from "../../services/config";
import { getSfadCourses } from "../../services/course.service";

function PlanTemplate({ isAdmin }) {
  const planData = useLoaderData();

  const [usersPresence, setUsersPresence] = useState([]);

  // Crud store data
  const [
    isMoveActionModalOpen,
    moveActionItem,
    selectedContent,
    isCrudChapterOpen,
    chapterModel,
    isCrudContentOpen,
    contentModel,
    isDeleteModalOpen,
    deleteItem,
    deleteConf,
    chapterList,
    loadingSave,
    isChaptersOrdered,
    searchModel,
    updateState,
    updateEditorContents,
    duplicateContent,
    deleteContent,
    deleteList,
  ] = usePlanTemplateStore((state) => [
    state.isMoveActionModalOpen,
    state.moveActionItem,
    state.selectedContent,
    state.isCrudChapterOpen,
    state.chapterModel,
    state.isCrudContentOpen,
    state.contentModel,
    state.isDeleteModalOpen,
    state.deleteItem,
    state.deleteConf,
    state.chapterList,
    state.loadingSave,
    state.isChaptersOrdered,
    state.searchModel,
    state.updateState,
    state.updateEditorContents,
    state.duplicateContent,
    state.deleteContent,
    state.deleteList,
  ]);

  /**
   * Socket Users Presence
   */
  useEffect(() => {
    SocketEcho.join(`template-plans.${planData.id}`)
      .here(initUserPresence)
      .joining(addUserPresence)
      .leaving(removeUserPresence)
      .error((error) => {
        console.error(error);
      });

    return () => {
      SocketEcho.leave(`template-plans.${planData.id}`);
    };
  }, []);

  const { saveModify } = usePlanTemplate();

  const [findEdit, setFindEdit] = useState(false);

  const [isStatusCrudOpen, setIsStatusCrudOpen] = useState(false);

  const getCoursesList = () => {
    getSfadCourses({}, (res) => {
      updateState({ courses: res.data });
    });
  };

  /**
   * Update Goal
   * @param content
   * @param key
   * @param value
   */
  const updateGoal = (content, key, value) => {
    if (!content?.localStatus) {
      content.localStatus = "edit";
    }
    content.data[key] = value;
    updateState({ chapterList: [...chapterList] });
  };

  /**
   * Delete item
   */
  const deleteSelected = () => {
    if (selectedContent.content_type === "template_chapter_content") {
      updateState({
        deleteConf: {
          title: "Elimina documento",
          icon: "icon_16 default text_box",
          subtitle: selectedContent.data.name,
          confirmHandler: deleteContent,
        },
        deleteItem: selectedContent,
        isDeleteModalOpen: true,
      });
    } else {
      updateState({
        deleteConf: {
          title: "Elimina obiettivo",
          icon: "icon_16 default layers",
          subtitle: selectedContent.data.name,
          description: selectedContent.data.description,
          confirmHandler: deleteContent,
        },
        deleteItem: selectedContent,
        isDeleteModalOpen: true,
      });
    }
  };

  const addUserPresence = (user) => {
    setUsersPresence((users) => [...users, user]);
  };

  const removeUserPresence = (user) => {
    setUsersPresence((users) =>
      users.filter((item) => item.user.id !== user.user.id),
    );
  };

  const initUserPresence = (items) => {
    setUsersPresence(items);
  };

  /**
   * Template Nav
   * @returns
   */
  const templateNav = () => (
    <div className="main_comp_actions_group">
      {/* Status */}
      {selectedContent.content_type === "template_goal" && (
        <div className="main_list_status_container">
          <StatusBtnItem
            active={selectedContent.data.status === "new"}
            onClick={() =>
              updateGoal(
                selectedContent,
                "status",
                selectedContent.data.status === "new" ? "default" : "new",
              )
            }
            icon="megaphone"
            tooltip="Nuovo"
          />

          <StatusBtnItem
            active={selectedContent.data.status === "modified"}
            onClick={() =>
              updateGoal(
                selectedContent,
                "status",
                selectedContent.data.status === "modified"
                  ? "default"
                  : "modified",
              )
            }
            icon="update"
            tooltip="Modificato"
          />
        </div>
      )}

      {/* Actions */}
      <div
        className={
          selectedContent.content_type === "template_chapter_content"
            ? "main_list_counter"
            : "main_actions"
        }
      >
        <ActionBtnItem
          icon="edit"
          onClick={() =>
            updateState({
              contentModel: { model: selectedContent },
              isCrudContentOpen: true,
            })
          }
          tooltip="Modifica"
        />

        {!searchModel.length && (
          <ActionBtnItem
            icon="duplicate"
            onClick={() =>
              duplicateContent(selectedContent, selectedContent.index)
            }
            tooltip="Duplica"
          />
        )}

        <ActionBtnItem
          icon="delete"
          tooltip="Elimina"
          onClick={deleteSelected}
        />
      </div>
    </div>
  );

  const canSave = () =>
    findError === undefined && (findEdit || isChaptersOrdered);

  /**
   * Template header
   * @returns
   */
  const templateHeader = () => (
    <div className="flex gap-5 items-center">
      {usersPresence.length > 1 && (
        <div
          data-tooltip-id="tooltip_small_top"
          data-tooltip-offset={10}
          data-tooltip-position-strategy="fixed"
          data-tooltip-content={usersPresence
            .map((item) => `${item.user.first_name} ${item.user.last_name}`)
            .join("\n")}
          className="flex items-center gap-3  fade_in bg-danger-100 rounded-full font-medium pl-3 pr-[1px] text-white py-[1px]"
        >
          <div className="flex items-center gap-2">
            <div className="icon_16 default user !bg-white icon_mask"></div>
            {usersPresence.length}
          </div>

          <div className="bg-white p-[6px] rounded-full flex">
            <div className="icon_16 default danger"></div>
          </div>
        </div>
      )}
      <button
        className={"button secondary small fill"}
        onClick={() => setIsStatusCrudOpen(true)}
      >
        Gestione stati
      </button>

      <button
        onClick={canSave() ? saveModify : null}
        className={
          "button primary small fill" + (!canSave() ? " disabled" : "")
        }
      >
        Salva
      </button>
    </div>
  );

  const preventNavigation = (e) =>
    findEdit || isChaptersOrdered ? (e.returnValue = true) : null;

  useEffect(() => {
    getCoursesList();
    updateState({ chapterList: planData.contents, planDetail: planData });
  }, []);

  useEffect(() => {
    setFindEdit(
      chapterList.find((c) => {
        if (
          !!deleteList.reqs.length ||
          !!deleteList.goals.length ||
          !!deleteList.contents.length ||
          !!deleteList.courses.length ||
          !!deleteList.chapters.length
        )
          return true;

        if (c.localStatus || c.orderGoals || c.orderContents) return true;

        const findContent = c.data.contents.find((content) => {
          if (content.localStatus || content.orderReqs) return true;

          if (content.content_type === "template_goal")
            return content.data.template_requirements.find(
              (req) => req.localStatus,
            );
        });

        if (findContent) return findContent;

        return false;
      }),
    );
  }, [chapterList]);

  useEffect(() => {
    window.addEventListener("beforeunload", preventNavigation);

    return () => {
      window.removeEventListener("beforeunload", preventNavigation);
    };
  }, [findEdit, isChaptersOrdered]);

  const findError = chapterList.find((c) =>
    c.data.contents.find(
      (g) =>
        g?.data?.template_requirements &&
        g.data.template_requirements.find((req) => req.errorValidationStatus),
    ),
  );

  const notFindContents = chapterList.filter((c) => c.data.contents.length > 0);

  return (
    <LayoutSidebar>
      <SubHeader
        title={planData.name}
        titleSmall
        description={planData.description}
        back
        backHref="/"
        TemplateRightContent={templateHeader}
      />

      <CollapseContainerContextProvider>
        <Sidebar isAdmin={isAdmin} hasChangeDetected={!!findEdit} />
      </CollapseContainerContextProvider>

      <div className="main_page_sidebar">
        {selectedContent ? (
          <>
            <SubNav
              category={
                selectedContent.content_type === "template_chapter_content" &&
                `documento ${selectedContent.index}`
              }
              title={`${selectedContent.data.name}${
                selectedContent?.data?.description
                  ? ` – ${selectedContent.data.description}`
                  : ""
              }`}
              TemplateRightContent={templateNav}
            />

            <div className="wrap_page_sidebar">
              {selectedContent.content_type === "template_chapter_content" ? (
                <EditorContent
                  key={selectedContent.content_id}
                  onChange={updateEditorContents}
                  initVal={selectedContent.data.editorjs}
                  headerLevels={[2]}
                />
              ) : (
                <GoalsContent
                  key={selectedContent.content_id}
                  isEditable={isAdmin}
                />
              )}
            </div>
          </>
        ) : (
          chapterList.length > 0 &&
          (notFindContents.length === 0 ? (
            <EmptyTemplate
              image="/images/empty/empty-contents.svg"
              title="Nessun contenuto da visualizzare"
              description="Crea un documento o un nuovo obiettivo."
            />
          ) : (
            <EmptyTemplate
              image="/images/empty/empty-selected.svg"
              title="Seleziona un contenuto dal menù laterale"
              description="Seleziona un documento testuale o un obiettivo di una sezione per modificarlo."
            />
          ))
        )}

        {chapterList.length === 0 && (
          <EmptyTemplate
            image="/images/empty/empty-chapts.svg"
            title="Nessuna sezione presente"
            description="Il piano è completamente vuoti. Crea una nuova sezione."
          />
        )}
      </div>

      {/* Move modal */}
      <MoveActionModal
        open={isMoveActionModalOpen}
        setOpen={(isOpen) => updateState({ isMoveActionModalOpen: isOpen })}
        action={moveActionItem}
      />

      {/* Crud Chapter */}
      <CrudChapterModal
        open={isCrudChapterOpen}
        setOpen={(isOpen) => updateState({ isCrudChapterOpen: isOpen })}
        chapterModel={chapterModel}
        onInsert={() => updateState({ isCrudChapterOpen: false })}
        onEdit={() => updateState({ isCrudChapterOpen: false })}
      />

      {/* Crud Content */}
      <CrudContentModal
        open={isCrudContentOpen}
        setOpen={(isOpen) => updateState({ isCrudContentOpen: isOpen })}
        contentModel={contentModel}
        onInsert={() => updateState({ isCrudContentOpen: false })}
        onEdit={() => updateState({ isCrudContentOpen: false })}
      />

      {/* Confirm content */}
      <ConfirmModal
        open={isDeleteModalOpen}
        setOpen={(isOpen) => updateState({ isDeleteModalOpen: isOpen })}
        title={deleteConf.title}
        icon={deleteConf.icon}
        subtitle={deleteConf.subtitle}
        description={deleteConf.description}
        alertMessage="Attenzione! Questa operazione è irreversibile! <b>Sei sicuro di procedere?</b>"
        ctaLabel="Elimina"
        onConfirm={() => deleteConf.confirmHandler(deleteItem)}
      />

      {loadingSave && <LoaderPage title="Salvataggio in corso..." />}

      <Prompt
        when={!!findEdit || isChaptersOrdered}
        message="Vuoi uscire senza salvare? tutte le modifiche andranno perse!"
      />

      <StatusPlanCrud
        open={isStatusCrudOpen}
        setOpen={setIsStatusCrudOpen}
        planTemplate={planData}
      />
    </LayoutSidebar>
  );
}

export default PlanTemplate;
