function useCalendly() {
  /**
   * Open Calendly
   */
  const openCalendly = () => {
    if (localStorage.getItem("session_piano")) {
      const session_piano = JSON.parse(localStorage.getItem("session_piano"));
      window
        .open(
          `${process.env.REACT_APP_HOST_CLIENT_SUPPORTO}/auth-session?token=${session_piano.token}`,
          "_blank",
        )
        .focus();
    }
  };

  return { openCalendly };
}

export default useCalendly;
