import { json } from "react-router-dom";
import { getOrganization, userLogin } from "../services/auth.service";
import { getTemplatePlan } from "../services/plans-template.service";
import {
  getOrganizationPlan,
  getWorkingPlan,
} from "../services/organizations.service";
import { getPlanApprovals, getPlans } from "../services/plans.service";
import { getAgenda, getLastAnnotation } from "../services/agendas.service";
import {
  getLocalSession,
  setLocalSession,
  updateAuthToken,
} from "./common.helper";

/**
 * Check Role Owner
 * @returns
 */
export const authSession = async ({ params, request }) => {
  const organizationPlan = new URL(request.url).searchParams.get(
    "organizationPlan",
  );

  localStorage.removeItem("sessionUser");

  localStorage.removeItem("session_piano");

  const { sessionToken, idUser } = params;

  updateAuthToken(sessionToken);

  const res = await userLogin({ id: idUser });

  if (res?.data?.id && ["reseller", "admin", "organization"].includes(res?.data?.role)) {
    setLocalSession(sessionToken, idUser, res.data);
    return {
      user: res.data,
      redirectOrganizationPlan: organizationPlan ?? null,
    };
  }

  return { notSession: true };
};

/**
 * Check session
 * @returns
 */
export const checkSession = async () => {
  const session = getLocalSession();

  if (!session) {
    window.location = process.env.REACT_APP_HOST_PLATFORM;
    return { notSession: true };
  }

  updateAuthToken(session.token);

  const res = await userLogin({ id: session.id });

  if (res?.data?.id) {
    setLocalSession(session.token, session.id, res.data);
    return {};
  }

  return { notSession: true };
};

/**
 * Check plan exist
 * @returns
 */
export const checkPlanTemplate = async ({ params }) => {
  const plan = await getTemplatePlan(
    {
      id: params.id,
      include: [
        "templateChapters",
        "templateChapters.templateChapterContents",
        "templateChapterContentsCount",
        "templateChapters.templateGoals",
        "templateGoalsCount",
        "templateChapters.templateGoals.templateRequirements",
        "templateRequirementsCount",
        "requirementStatuses",
      ],
    },
    undefined,
    () => {
      throw new Response("Not Found", { status: 404 });
    },
  );

  return plan.data;
};

/**
 * Check plan exist
 * @returns
 */
export const getPlanTemplate = async ({ params }) => {
  const plan = await getTemplatePlan(
    {
      id: params.id,
      include: [
        "templateChaptersCount",
        "templateChapterContentsCount",
        "templateGoalsCount",
        "templateRequirementsCount",
        "templateChapters",
        "templateChapters.templateChapterContents",
        "templateChapters.templateGoals",
        "templateChapters.templateGoals.templateRequirements",
        "requirementStatuses",
        "templatePrintPresets",
      ],
    },
    undefined,
    () => {
      throw new Response("Not Found", { status: 404 });
    },
  );

  return plan.data;
};

/**
 * Check plan exist
 * @returns
 */
export const loaderCopyTemplate = async ({ params }) => {
  const body = {
    id: params.idCorp,
    idPlan: params.idPlan,
    include: [
      "organization",
      "templatePlan",
      "organizationChapters",
      "organizationChapterContentsCount",
      "organizationGoalsCount",
      "organizationRequirementsCount",
      "organizationChapters.templateChapter",
      "organizationChapters.organizationChapterContents",
      "organizationChapters.organizationChapterContents.templateChapterContent",
      "organizationChapters.organizationGoals",
      "organizationChapters.organizationGoals.templateGoal",
      "organizationChapters.organizationGoals.organizationRequirements",
      "organizationChapters.organizationGoals.organizationRequirements.templateRequirement",
    ],
  };

  const plan = await getOrganizationPlan(body, undefined, () => {
    throw new Response("Not Found", { status: 404 });
  });

  return { plan: plan.data };
};

/**
 * Check working plan exist
 * @returns
 */
export const loaderEntityDetail = async ({ params }) => {
  const body = {
    id: params.id,
    include: [
      "organizationChaptersCount",
      "organizationChapterContentsCount",
      "organizationGoalsCount",
      "organizationRequirementsCount",
      "templatePlan",
    ],
    filter: {
      "templatePlan.working": true,
    },
    append: [
      "organization_requirements_completion_percentage",
      "organization_requirements_statuses",
      "template_goals_statuses",
      "template_requirements_statuses",
      "completion_percentage",
      "organization_chapter_contents_statuses",
      "attachments_count",
      "requirements_attachments_count",
    ],
  };

  const organization = await getOrganization(
    { id: params.id },
    undefined,
    () => {
      throw new Response("Not Found", { status: 404 });
    },
  );

  try {
    // Fetch Api
    let workingPlans = await getPlans(body, undefined, () => {
      throw new Response("Not Found", { status: 200 });
    });

    return { organization: organization.data, workingPlans: workingPlans.data };
  } catch (error) {
    return { organization: organization.data };
  }
};

/**
 * Check working plan exist
 * @returns
 */
export const loaderEntityPlan = async ({ params }) => {
  const body = {
    id: params.id,
    idPlan: params.idPlan,
    include: [
      "agenda",
      "organization",
      "templatePlan",
      "organizationChapters",
      "organizationChaptersCount",
      "organizationChapterContentsCount",
      "organizationGoalsCount",
      "organizationRequirementsCount",
      "organizationChapters.templateChapter",
      "organizationChapters.organizationChapterContents",
      "organizationChapters.organizationChapterContents.templateChapterContent",
      "organizationChapters.organizationGoals",
      "organizationChapters.organizationGoals.templateGoal",
      "organizationChapters.organizationGoals.organizationRequirements",
      "organizationChapters.organizationGoals.organizationRequirements.templateRequirement",
      "templatePlan.templatePrintPresets",
    ],
    append: [
      "organization_requirements_completion_percentage",
      "organization_requirements_statuses",
      "template_goals_statuses",
      "template_requirements_statuses",
      "completion_percentage",
      "organization_chapter_contents_statuses",
    ],
  };

  const plan = await getOrganizationPlan(body, undefined, () => {
    throw new Response("Not Found", { status: 404 });
  });

  const approvals = await getPlanApprovals(
    { idCorp: params.id, idPlan: params.idPlan },
    undefined,
    () => {
      /* throw new Response("Not Found", { status: 404 }); */
    },
  );

  return {
    planData: plan.data,
    approvalsList: approvals?.data ? approvals.data : [],
  };
};

/**
 * Check Plan to compile
 * @returns
 */
export const loaderCompilePlan = async ({ params }) => {
  const body = {
    id: params.id,
    idPlan: params.idPlan,
    include: [
      "agenda",
      "organization",
      "templatePlan",
      "organizationChapters",
      "organizationChaptersCount",
      "organizationChapterContentsCount",
      "organizationGoalsCount",
      "organizationRequirementsCount",
      "organizationChapters.organizationGoals.organizationRequirements.templateRequirement",
      "templatePlan.templatePrintPresets",
    ],
    append: ["organization_requirements_statuses"],
  };

  const plan = await getOrganizationPlan(body, undefined, () => {
    throw new Response("Not Found", { status: 404 });
  });

  return plan.data;
};

/**
 * Check Plan to compile
 * @returns
 */
export const loaderListAgenda = async ({ params }) => {
  const lastAnnotation = await getLastAnnotation();

  return lastAnnotation?.data?.id ? lastAnnotation.data : null;
};

/**
 * Check Plan to compile
 * @returns
 */
export const loaderAgendaDetail = async ({ params }) => {
  const body = {
    id: params.id,
    idCorp: params.idCorp,
  };

  const agenda = await getAgenda(body, undefined, () => {
    throw new Response("Not Found", { status: 404 });
  });

  return { ...agenda.data, organization_id: params.idCorp };
};

/**
 * User redirect
 * @param {*} param0
 * @returns
 */
export const redirectUser = async ({ params }) => {
  const session = getLocalSession();

  if (session) {
    updateAuthToken(session.token);

    const body = { id: session.id };
    const res = await userLogin(body);

    if (res?.data?.id) {
      return { user: res.data };
    }

    return { noSession: true };
  }

  return { noSession: true };
};

/**
 * Check Admin
 * @param {*} param0
 * @returns
 */
export const checkAdmin = async ({ params }) => {
  const session = getLocalSession();

  if (session?.user?.role === "admin") {
    return null;
  }

  throw json({ message: "No session" }, { status: 404 });
};

/**
 * Check Reseller
 * @param {*} param0
 * @returns
 */
export const checkReseller = async ({ params }) => {
  const session = getLocalSession();

  if (session?.user?.role === "reseller") {
    return null;
  }

  throw json({ message: "No session" }, { status: 404 });
};

/**
 * Check organization
 * @param {*} param0
 * @returns
 */
export const checkOrganization = async ({ params }) => {
  const session = getLocalSession();

  if (session?.user?.role === "organization") {
    return null;
  }

  throw json({ message: "No session" }, { status: 404 });
};

export const onganizationDetail = async ({ params }) => {
  const session = getLocalSession();

  const body = {
    id: session.user.organization_id,
    include: [
      "organizationChaptersCount",
      "organizationChapterContentsCount",
      "organizationGoalsCount",
      "organizationRequirementsCount",
      "templatePlan",
    ],
    filter: {
      "templatePlan.working": true,
    },
    append: [
      "organization_requirements_completion_percentage",
      "organization_requirements_statuses",
      "template_goals_statuses",
      "template_requirements_statuses",
      "completion_percentage",
      "organization_chapter_contents_statuses",
      "attachments_count",
      "requirements_attachments_count",
    ],
  };

  try {
    // Fetch Api
    let workingPlans = await getPlans(body, undefined, () => {
      throw new Response("Not Found", { status: 200 });
    });

    return {
      organization: { id: session.user.organization_id },
      workingPlans: workingPlans.data,
    };
  } catch (error) {
    return { organization: { id: session.user.organization_id } };
  }
};

/**
 * Check working plan exist
 * @returns
 */
export const loaderOrganizationPlan = async ({ params }) => {
  const session = getLocalSession();

  const body = {
    id: session.user.organization_id,
    idPlan: params.idPlan,
    include: [
      "agenda",
      "organization",
      "templatePlan",
      "organizationChapters",
      "organizationChaptersCount",
      "organizationChapterContentsCount",
      "organizationGoalsCount",
      "organizationRequirementsCount",
      "organizationChapters.templateChapter",
      "organizationChapters.organizationChapterContents",
      "organizationChapters.organizationChapterContents.templateChapterContent",
      "organizationChapters.organizationGoals",
      "organizationChapters.organizationGoals.templateGoal",
      "organizationChapters.organizationGoals.organizationRequirements",
      "organizationChapters.organizationGoals.organizationRequirements.templateRequirement",
      "templatePlan.templatePrintPresets",
    ],
    append: [
      "organization_requirements_completion_percentage",
      "organization_requirements_statuses",
      "template_goals_statuses",
      "template_requirements_statuses",
      "completion_percentage",
      "organization_chapter_contents_statuses",
    ],
  };

  const plan = await getOrganizationPlan(body, undefined, () => {
    throw new Response("Not Found", { status: 404 });
  });

  const approvals = await getPlanApprovals(
    { idCorp: session.user.organization_id, idPlan: params.idPlan },
    undefined,
    () => {
      /* throw new Response("Not Found", { status: 404 }); */
    },
  );

  return {
    planData: plan.data,
    approvalsList: approvals?.data ? approvals.data : [],
  };
};

/**
 * Check Plan to compile
 * @returns
 */
export const loaderOrganizationCompile = async ({ params }) => {
  const session = getLocalSession();

  const body = {
    id: session.user.organization_id,
    idPlan: params.idPlan,
    include: [
      "agenda",
      "organization",
      "templatePlan",
      "organizationChapters",
      "organizationChaptersCount",
      "organizationChapterContentsCount",
      "organizationGoalsCount",
      "organizationRequirementsCount",
      "organizationChapters.organizationGoals.organizationRequirements.templateRequirement",
      "templatePlan.templatePrintPresets",
    ],
    append: ["organization_requirements_statuses"],
  };

  const plan = await getOrganizationPlan(body, undefined, () => {
    throw new Response("Not Found", { status: 404 });
  });

  return plan.data;
};
